<template>
  <div class="preview-periodic-benefit-template">
    <PageTitle
      title="查看會員權益範本"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({ name: 'PeriodicBenefitTemplateList' })"
    />

    <section class="section-block">
      <p class="section-title">基本設定</p>
      <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem label="會員權益範本性質">
          {{ displayData.type }}
        </BaseElFormItem>

        <BaseElFormItem label="會員權益範名稱" prop="name">
          <BaseElInput v-model="formData.name" placeholder="請輸入" />
        </BaseElFormItem>

        <BaseElFormItem label="圖片" prop="img">
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>

        <BaseElFormItem label="商品描述">
          <div v-html="displayData.description" />
        </BaseElFormItem>

        <BaseElFormItem label="發放時間" prop="timeRange">
          <BaseElSelect v-model="formData.timeRange">
            <BaseElSelectOption
              v-for="option in timeRangeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </BaseElSelect>
        </BaseElFormItem>

        <BaseElFormItem label="排序" prop="order">
          <BaseElInput v-model="formData.order" />
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <section class="section-block">
      <p class="section-title">權益項目</p>
      <div class="text-gray-80">
        <ul class="list-disc" style="padding-left: 20px">
          <li v-for="(content, idx) in displayData.entitlements" :key="idx">
            {{ content }}
          </li>
        </ul>
      </div>
    </section>

    <section class="section-block">
      <p class="section-title">發放週期</p>
      <div class="text-gray-80" v-html="displayData.period" />
    </section>

    <section class="section-block">
      <p class="section-title">其他發放條件</p>
      <div v-if="displayData.conditions.length" class="text-gray-80">
        <ul class="list-disc" style="padding-left: 20px">
          <li v-for="(content, idx) in displayData.conditions" :key="idx">
            {{ content }}
          </li>
        </ul>
      </div>
      <div v-else>
        無
      </div>
    </section>

    <ImageCropper
      v-if="modal.imgCropper"
      :image="imagePlaceHolder"
      @close="modal.imgCropper = false"
      @uploaded="getImage"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { FindPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import { useShop } from '@/use/shop'
import { useRoute } from 'vue-router/composables'
import { useBaseForm } from '@/use/useForm'
import { get, map, join, find, filter } from 'lodash'
import { useMemberLevel } from '@/use/memberLevel'
import { noEmptyRules } from '@/validation'
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'

export default defineComponent({
  name: 'PreviewPeriodBenefitTemplate',
  components: {
    ImageCropper,
    UploadButton,
  },
  setup (props) {
    const { shopId } = useShop()
    const route = useRoute()

    const { formData, formRef, initFormData, loading } = useBaseForm()
    const { getMemberLevelConfig, memberLevelConfig } = useMemberLevel()
    const { formatPeriodPreviewText, weekDayOptions } = usePeriodBenefitTemplate()
    const configData = ref({})
    const imagePlaceHolder = ref(null)
    const modal = reactive({
      imgCropper: false,
    })

    initFormData({
      name: null,
      timeRange: null,
      order: null,
      img: null,
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        timeRange: [noEmptyRules()],
        order: [noEmptyRules()],
      }
      return rules
    })

    const timeRangeOptions = computed(() => {
      return [
        { label: '凌晨（00:00～06:00）', value: 'earlyMorning' },
        { label: '上午（06:00～12:00）', value: 'morning' },
        { label: '下午（12:00～18:00）', value: 'afternoon' },
        { label: '晚間（18:00～24:00）', value: 'night' },
      ]
    })

    const displayPeriodPreviewText = computed(() => {
      const periodConfig = get(configData.value, 'period', {})
      const startType = periodConfig.startType
      const periodType = periodConfig.type
      const instantAward = get(configData.value, 'instantAward')
      let fixedWeekDay = '-'
      const nDayValue = periodConfig.nDayValue || '-'
      const fixedValue = periodConfig.fixedValue || '-'
      const totalAwardCount = get(configData.value, 'totalAwardCount', '-')
      if (periodType === 'weekly' && startType === 'fixed') {
        fixedWeekDay = fixedValue
      }

      return formatPeriodPreviewText({
        startType,
        periodType,
        fixedWeekDay,
        nDayValue,
        fixedValue,
        instantAward,
        totalAwardCount,
      })
    })

    const levelConditionPreviewText = computed(() => {
      const conditions = get(configData.value, 'conditions', [])

      const levelCondition = find(conditions, { type: 'level' })

      if (levelCondition) {
        const levels = map(get(levelCondition, 'levels', []), (level) => {
          const levelName = get(find(memberLevelConfig.value, { level }), 'name', '-')
          return `「${levelName}」`
        })
        return `發放時的會員等級：${join(levels, '或')}`
      }

      return ''
    })

    const findPeriodicBenefitTemplate = async () => {
      loading.value = true
      const [res, err] = await FindPeriodicBenefitTemplate({
        shopId: shopId.value,
        id: route.params.id,
      })

      loading.value = false

      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res

      formData.name = get(res, 'name')
      formData.timeRange = get(res, 'period.timeRange')
      formData.order = get(res, 'order')
    }

    const displayData = computed(() => {
      const failConditionCount = get(configData.value, 'failConditionCount')
      const templateType = get(configData.value, 'type', '')
      return {
        type: templateType === 'subscription' ? '訂閱商品' : '非訂閱商品',
        description: get(configData.value, 'description', ''),
        entitlements: map(get(configData.value, 'entitlements', '-'), (item) => {
          const itemData = get(item, 'StandardAward', {})
          if (itemData.type === 'classTicket') {
            return `${itemData.name}（${itemData.amount} 份）`
          } else if (itemData.type === 'coupon') {
            return `${itemData.name}（${itemData.amount} 張）`
          } else if (itemData.type === 'pointCard') {
            return `${itemData.name}（${itemData.amount} 張）`
          } else if (itemData.type === 'punchCard') {
            return `${itemData.name}（${itemData.amount} 份）`
          } else if (itemData.type === 'wallet') {
            return `儲值金（${itemData.amount} 元）`
          } else if (itemData.type === 'cashback') {
            return `回饋金（${itemData.amount} 元）`
          } else if (itemData.type === 'point') {
            return `點數（${itemData.amount} 點）`
          }
        }),
        period: displayPeriodPreviewText.value,
        conditions: filter([
          levelConditionPreviewText.value,
          failConditionCount ? '發放會員權益時，如會員不符條件，仍扣除發放次數' : '',
        ], (content) => content),
      }
    })

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      modal.imgCropper = true
    }

    const getImage = (data) => {
      formData.img = data
      modal.imgCropper = false
    }

    onBeforeMount(async () => {
      findPeriodicBenefitTemplate()
      getMemberLevelConfig()
    })
    return {
      loading,
      formData,
      formRef,
      displayData,
      timeRangeOptions,
      formRules,
      modal,
      imagePlaceHolder,
      loadImg,
      getImage,
    }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply text-primary-100 font-medium text-md mb-[12px];
}
</style>
